import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, CardText, Row, Col } from 'reactstrap';
import blogData from '../../data/blogData.json';

const BlogList = () => {
  // 按日期排序博客文章（从新到旧）
  const sortedPosts = [...blogData.posts].sort((a, b) => 
    new Date(b.date) - new Date(a.date)
  );

  return (
    <div>
      <Helmet>
        <meta name="description" content="Explore best practices, design tips and business applications for email signatures. Learn how to create professional email signatures to enhance your business communications." />
         <link rel="canonical" href="https://www.easysigngen.com/blog" />
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Blog",
            "name": "EasySignGen Blog",
            "description": "Professional email signature tips and best practices",
            "url": "https://www.easysigngen.com/blog",
            "blogPosts": blogData.posts.map(post => ({
              "@type": "BlogPosting",
              "headline": post.title,
              "datePublished": post.date,
              "description": post.summary,
              "url": `https://www.easysigngen.com/blog/${post.slug}`
            }))
          })}
        </script>
      </Helmet>
      
      <h1 className="text-center mb-4" style={{color:"#8214B6"}}>Blog Posts</h1>
      <Row>
        {sortedPosts.map(post => (
          <Col md="6" className="mb-4" key={post.id}>
            <Card className="h-100 shadow-sm">
              <CardBody>
                <CardTitle tag="h5">
                  <Link 
                    to={`/blog/${post.slug}`}
                    className="text-decoration-none"
                    style={{color: "#8214B6"}}
                  >
                    {post.title}
                  </Link>
                </CardTitle>
                <CardText className="text-muted small">{post.date}</CardText>
                <CardText>{post.summary}</CardText>
                <Link 
                  to={`/blog/${post.slug}`}
                  className="btn btn-outline-primary rounded-pill"
                  style={{borderColor: "#8214B6", color: "#8214B6"}}
                >
                  Read More
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BlogList;