import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap';
import {BrowserRouter as Router,Switch,Route,Link} from "react-router-dom";
import {storage} from './services/firebase'
import { uploadImage, getOptimizedUrl } from './services/cloudinary';
import AppNavBar from './component/AppNavBar'
import AppHeading from './component/AppHeading'
import AppCollapseNavBar from './component/leftContainer/AppCollapseNavBar'
import SignatureContainer from './component/rightContainer/SignatureContainer'
import UserGuide from './component/UserGuide'
import './App.css';
import { Helmet } from 'react-helmet';
import Blog from './component/blog/Blog'
import FAQ from './component/help/FAQ'
import Contact from './component/Contact'
import HandwrittenSignature from './component/HandwrittenSignature'



class App extends Component {
  constructor(props){
    super(props)

    this.state={
      content:[{index:"1",id:'Full Name',type:"text",userInput:'',min:"20",max:"40"},{index:"2",id:'Role',type:"text",userInput:'',min:"20",max:"40"},{index:"1",id:'Email',type:"text",userInput:'',min:"20",max:"40"},{index:"2",id:'Phone',type:"text",userInput:'',min:"0",max:"0"},{index:"1",id:'Address',type:"text",userInput:'',min:"0",max:"0"},{index:"2",id:'Website',type:"text",userInput:'',min:"0",max:"0"}],
      social:[{index:"1",id:'FaceBook',userInput:'',type:"text",min:"20",max:"30",icon:'facebook.png'},{index:"2",id:'Instagram',type:"text",userInput:'',min:"20",max:"30",icon:'instagram.png'},{index:"1",id:'Twitter',type:"text",userInput:'',min:"20",max:"30",icon:'twitter.png'},{index:"2",id:'Linkedin',type:"text",userInput:'',min:"20",max:"30",icon:'linkedin.png'},{index:"2",id:'YouTube',type:"text",userInput:'',min:"20",max:"30",icon:'youtube.png'}],
      images:[{index:"1",id:"Logo",type:'file',userInput:'',min:"35",max:"60"},{index:"1",id:"Profile",type:'file',userInput:'',min:"35",max:"60"}],
      style:[{index:"1",id:"Background Color",userInput:'',type:'color',min:"20",max:"40"},{index:"1",id:"Text Color",userInput:'',type:'color',min:"20",max:"40"},{index:"2",id:"Background Rounding",userInput:'0',type:'range',min:"0",max:"35"},{index:"2",id:"Row Spacing",userInput:'2',type:'range',min:"2",max:"5"},{index:"1",id:"Logo Size",userInput:'65',type:'range',min:"65",max:"80"},{index:"2",id:"Logo Image Rounding",userInput:'0',type:'range',min:"20",max:"40"},{index:"1",id:"Profile Image Size",userInput:'55',type:'range',min:"55",max:"61"},{index:"2",id:"Profile Image Rounding",userInput:'0',type:'range',min:"0",max:"40"},{index:"1",id:"Social Size",userInput:'20',type:'range',min:"20",max:"22"},{index:"2",id:"Social Rounding",userInput:'0',type:'range',min:"0",max:"30"}],
      isOpenNavBar:false,
      isOpenModal:false,
      isChange:false,
      copied: false,
    }

  }

  handleImageChange = async (event, inputId, listName) => {
    if (event.target.files[0]) {
      try {
        this.setState({ isLoading: true });
        
        const file = event.target.files[0];
        
        // 创建 FileReader 来读取文件
        const reader = new FileReader();
        reader.readAsDataURL(file);
        
        reader.onload = async () => {
          // 上传图片
          const { url, publicId } = await uploadImage(reader.result);

          // 更新状态
          let updateList = [...this.state[listName]];
          let updateObj = updateList.find(el => el.id === inputId);
          let index = updateList.findIndex(el => el.id === inputId);
          
          updateObj.userInput = url;
          updateObj.publicId = publicId;
          updateList[index] = updateObj;
          
          this.setState({
            [listName]: updateList,
            isLoading: false
          });
        };

      } catch (error) {
        console.error('图片上传失败:', error);
        this.setState({ 
          error: error.message,
          isLoading: false 
        });
      }
    }
  };

  // 获取优化后的图片示例
  getOptimizedImage = (publicId) => {
    return getOptimizedUrl(publicId, {
      width: 300,
      height: 300,
      quality: 'auto',
      format: 'auto'
    });
  };

  componentDidMount(){ 
    this.state.social.forEach((el)=>{
      storage.ref(`social/${el.icon}`).getDownloadURL()
      .then(url=>{
        let updateList=this.state["social"];
        let updateObj=updateList.find(ell=>ell.id===el.id)
        let index= updateList.findIndex(ell=>ell.id===el.id)
        updateObj.icon=url;
        updateList[index]=updateObj;
        this.setState({listName:updateList})
      })  
    })   
      
    // initGA();
    // logPageView();
  }

  toggleNavBar=()=>{
    this.setState({isOpenNavBar:!this.state.isOpenNavBar});
  }

  toggleModal=()=>{
    this.setState({isOpenModal:!this.state.isOpenModal});
  }

  getList = (listName) => {
    return this.state[listName];
  }

  handleInputChange = (event,inputId,listName) =>{
    this.setState({isChange:true})
    event.preventDefault();
    let updateList=this.state[listName];
    let updateObj=updateList.find(el=>el.id===inputId)
    let index= updateList.findIndex(el=>el.id===inputId);
    updateObj.userInput=event.target.value;
    updateList[index]=updateObj;
    this.setState({listName:updateList})  
  }


  render() {
    return (
      <>
        <Helmet>
          <title>EasySignGen - Free Online Email & Handwritten Signature Generator</title>
          <meta name="description" content="Create professional email signatures and handwritten signatures with EasySignGen. Compatible with Gmail, Outlook, and Apple Mail. Customize with social media icons, logos, and personalized designs. Free to use, no registration required." />
        
          {/* Open Graph Tags */}
          <meta property="og:title" content="EasySignGen - Free Online Email & Handwritten Signature Generator" />
          <meta property="og:description" content="Create professional email signatures and handwritten signatures with EasySignGen. Supports multiple email clients with rich customization options." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.easysigngen.com" />
          
          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="EasySignGen - Free Online Signature Generator" />
          <meta name="twitter:description" content="Create professional email and handwritten signatures, completely free with no registration required." />
          
          {/* Additional SEO Meta Tags */}
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://www.easysigngen.com" />
          <meta name="author" content="EasySignGen" />
          
          {/* Google AdSense */}
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7876312287093199" crossorigin="anonymous"></script>
        </Helmet>
        <Router>
          <div className="App">
            <AppNavBar toggle={this.toggleNavBar} isOpen={this.state.isOpenNavBar}></AppNavBar>
            <Switch>
                <Route exact path="/">
                  <AppHeading/>
                  <Container>
                    <Row>
                      <Col className="mb-2">
                        <AppCollapseNavBar imgChange={this.handleImageChange} change={this.handleInputChange} getList={this.getList} />
                      </Col>
                      <Col>
                        <SignatureContainer isChange={this.state.isChange} isOpen={this.state.isOpenModal} toggle={this.toggleModal} list={this.state}/>
                      </Col>
                    </Row>
                  </Container>
                </Route>
                <Route exact path="/handwritten-signature">
                  <HandwrittenSignature />
                </Route>
                <Route exact path="/contact">
                  <Contact />
                </Route>
                <Route exact path="/help/email-signature-guide">
                  <UserGuide />
                </Route>
                <Route exact path="/help/faq">
                  <FAQ />
                </Route>
                <Route path="/blog">
                  <Blog />
                </Route>
            </Switch>  
            {/* <footer className="footer">
              <Container>
                <Row>
                  <Col>
                    <Link to="/blog">Blog</Link>
                  </Col>
                  <Col>
                    <Link to="/about">About</Link>
                  </Col>
                  <Col>
                    <Link to="/contact">Contact</Link>
                  </Col>
                </Row>
              </Container>
            </footer> */}
          </div>
        </Router>
      </>
    )
  }
}

export default App;
