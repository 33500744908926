import React, { Component } from 'react'
import { Container } from 'reactstrap';
import { createClient } from '@supabase/supabase-js'
import { Helmet } from 'react-helmet'



// 初始化 Supabase 客户端
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
)

export default class Contact extends Component {
    state = {
        name: '',
        email: '',
        message: '',
        isSubmitting: false
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.setState({ isSubmitting: true })

        try {
            const { data, error } = await supabase
                .from('contacts')
                .insert([
                    {
                        name: this.state.name,
                        email: this.state.email,
                        message: this.state.message
                    }
                ])

            if (error) throw error

            // 清空表单
            this.setState({
                name: '',
                email: '',
                message: '',
                isSubmitting: false
            })

        } catch (error) {
            console.error('提交失败:', error)
            this.setState({ isSubmitting: false })
        }
    }

    render() {
        const { isSubmitting } = this.state
        return (
            <div>
                <Helmet>
                    <link rel="canonical" href="https://www.easysigngen.com/contact" />
                </Helmet>
                <h1 className="text-center" style={{color:"#8214B6"}}>Contact Us</h1>
                <div className="w-50 mx-auto mt-4">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group mb-3">
                            <label htmlFor="name">Name</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="name"
                                placeholder="Enter your name"
                                value={this.state.name}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="email">Email</label>
                            <input 
                                type="email" 
                                className="form-control" 
                                id="email"
                                placeholder="Enter your email"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="message">Message</label>
                            <textarea 
                                className="form-control" 
                                id="message" 
                                rows="5"
                                placeholder="Enter your message"
                                value={this.state.message}
                                onChange={this.handleChange}
                            ></textarea>
                        </div>
                        <button 
                            type="submit" 
                            className="btn rounded-pill px-4"
                            style={{
                                backgroundColor: '#8214B6',
                                color: 'white'
                            }}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
    )}
} 