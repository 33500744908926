import React, { useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import '../styles/HandwrittenSignature.css';

const HandwrittenSignature = () => {
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const [color, setColor] = useState('#000000');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [minWidth, setMinWidth] = useState(10);
  const [maxWidth, setMaxWidth] = useState(20);
  const [undoStack, setUndoStack] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    signaturePadRef.current = new SignaturePad(canvas, {
      minWidth,
      maxWidth,
      penColor: color,
      backgroundColor
    });

    signaturePadRef.current.addEventListener("endStroke", () => {
      const data = signaturePadRef.current.toData();
      setCurrentData(data);
      setUndoStack([]);
    });

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      signaturePadRef.current.off();
    };
  }, [color, backgroundColor, minWidth, maxWidth]);

  const resizeCanvas = () => {
    const canvas = canvasRef.current;
    const container = canvas.parentElement;
    
    // 设置画布大小为容器大小
    canvas.style.width = `${container.clientWidth}px`;
    canvas.style.height = `${container.clientHeight}px`;
    
    // 设置画布实际像素以匹配显示大小
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = container.clientWidth * ratio;
    canvas.height = container.clientHeight * ratio;
    
    // 调整上下文缩放
    const context = canvas.getContext('2d');
    context.scale(ratio, ratio);
    
    // 如果 signaturePad 已经存在，需要清除并重新初始化
    if (signaturePadRef.current) {
      signaturePadRef.current.clear(); // 清除现有内容
    }
  };

  const handleUndo = () => {
    if (!signaturePadRef.current || currentData.length === 0) return;

    const data = [...currentData];
    const removedStroke = data.pop();
    setUndoStack([...undoStack, removedStroke]);
    setCurrentData(data);

    signaturePadRef.current.clear();
    if (data.length > 0) {
      signaturePadRef.current.fromData(data);
    }
  };

  const handleRedo = () => {
    if (!signaturePadRef.current || undoStack.length === 0) return;

    const newUndoStack = [...undoStack];
    const strokeToRestore = newUndoStack.pop();
    const newData = [...currentData, strokeToRestore];

    setUndoStack(newUndoStack);
    setCurrentData(newData);

    signaturePadRef.current.fromData(newData);
  };

  const handleClear = () => {
    // 清除画布
    signaturePadRef.current.clear();
    
    // 重置所有状态到初始值
    setCurrentData([]);
    setUndoStack([]);
    setMinWidth(10);
    setMaxWidth(20);
    setColor('#000000');
    setBackgroundColor('#ffffff');

    // 重置 SignaturePad 的属性
    if (signaturePadRef.current) {
      signaturePadRef.current.minWidth = 10;
      signaturePadRef.current.maxWidth = 20;
      signaturePadRef.current.penColor = '#000000';
      signaturePadRef.current.backgroundColor = '#ffffff';
    }
  };

  const handleSave = (format) => {
    if (signaturePadRef.current.isEmpty()) {
      alert("Please provide a signature first.");
      return;
    }

    const dataURL = signaturePadRef.current.toDataURL(`image/${format}`);
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = `signature.${format}`;
    link.click();
  };

  const handleMinWidthChange = (e) => {
    const newMinWidth = Number(e.target.value);
    setMinWidth(newMinWidth);
    
    // 只有当新的 minWidth 大于当前 maxWidth 时，才调整 maxWidth
    if (newMinWidth > maxWidth) {
      setMaxWidth(newMinWidth);
      if (signaturePadRef.current) {
        signaturePadRef.current.maxWidth = newMinWidth;
      }
    }
    
    if (signaturePadRef.current) {
      signaturePadRef.current.minWidth = newMinWidth;
    }
  };

  const handleMaxWidthChange = (e) => {
    const newMaxWidth = Number(e.target.value);
    setMaxWidth(newMaxWidth);
    
    // 只有当新的 maxWidth 小于当前 minWidth 时，才调整 minWidth
    if (newMaxWidth < minWidth) {
      setMinWidth(newMaxWidth);
      if (signaturePadRef.current) {
        signaturePadRef.current.minWidth = newMaxWidth;
      }
    }
    
    if (signaturePadRef.current) {
      signaturePadRef.current.maxWidth = newMaxWidth;
    }
  };

  return (
    <>
      <Helmet>
        <title>Free Online Handwritten Signature Generator - Draw & Create Digital Signatures | EasySignGen</title>
        <meta name="description" content="Create professional digital signatures online for free. Draw your signature or generate typed signatures in multiple formats (JPG, PNG, SVG). No registration required, no watermarks." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Free Online Signature Generator - Draw & Create Digital Signatures" />
        <meta property="og:description" content="Create professional digital signatures online for free. Draw your signature or generate typed signatures in multiple formats (JPG, PNG, SVG). No registration required." />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Free Online Signature Generator - Draw & Create Digital Signatures" />
        <meta name="twitter:description" content="Create professional digital signatures online for free. Draw your signature or generate typed signatures in multiple formats (JPG, PNG, SVG)." />
        
        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="EasySignGen" />
        <link rel="canonical" href="https://www.easysigngen.com/handwritten-signature" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebApplication",
              "name": "Online Signature Generator",
              "description": "Free tool to create digital signatures by drawing or typing. Supports multiple formats and styles.",
              "applicationCategory": "UtilityApplication",
              "operatingSystem": "Any",
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
              },
              "featureList": [
                "Draw signatures with mouse or touch",
                "Multiple output formats (JPG, PNG, SVG)",
                "Customizable colors and line weights",
                "No registration required",
                "No watermarks"
              ]
            }
          `}
        </script>
      </Helmet>

      <Container>
        <Row>
          <Col xs="12" md="7" lg="8" className="canvas-outer">
            <div className="canvas">
              <canvas 
                ref={canvasRef} 
                className="canvas__inner"
                style={{ touchAction: 'none', userSelect: 'none' }}
              />
            </div>
          </Col>
          <Col xs="12" md="5" lg="4">
            <div className="options">
              <div className="options-group">
                <h3 className="options-group__title">Colors</h3>
                <Row>
                  <Col xs="6">
                    <label className="field">
                      <p className="field__label">Pen Color</p>
                      <div className="field__color">
                        <input 
                          type="color" 
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                        />
                        <input 
                          type="text" 
                          value={color.replace('#', '')}
                          className="field__input-text"
                          onChange={(e) => setColor('#' + e.target.value)}
                        />
                      </div>
                    </label>
                  </Col>
                  <Col xs="6">
                    <label className="field">
                      <p className="field__label">Background</p>
                      <div className="field__color">
                        <input 
                          type="color" 
                          value={backgroundColor}
                          onChange={(e) => setBackgroundColor(e.target.value)}
                        />
                        <input 
                          type="text" 
                          value={backgroundColor.replace('#', '')}
                          className="field__input-text"
                          onChange={(e) => setBackgroundColor('#' + e.target.value)}
                        />
                      </div>
                    </label>
                  </Col>
                </Row>
              </div>

              <div className="options-group">
                <h3 className="options-group__title">Stroke Width</h3>
                <Row>
                  <Col xs="12">
                    <label className="field">
                      <p className="field__label">Min Width</p>
                      <div className="field__range">
                        <input 
                          type="range"
                          min="1"
                          max="100"
                          value={minWidth}
                          onChange={handleMinWidthChange}
                        />
                        <span className="field__range-value">{minWidth}</span>
                      </div>
                    </label>
                  </Col>
                  <Col xs="12">
                    <label className="field">
                      <p className="field__label">Max Width</p>
                      <div className="field__range">
                        <input 
                          type="range"
                          min="1"
                          max="100"
                          value={maxWidth}
                          onChange={handleMaxWidthChange}
                        />
                        <span className="field__range-value">{maxWidth}</span>
                      </div>
                    </label>
                  </Col>
                </Row>
              </div>

              <div className="options-group">
                <h3 className="options-group__title">Actions</h3>
                <Row>
                  <Col xs="12">
                    <div className="save">
                      <button 
                        onClick={handleUndo} 
                        className={`edit__button ${currentData.length === 0 ? 'inactive-btn' : ''}`}
                        disabled={currentData.length === 0}
                      >
                        UNDO
                      </button>
                      <button 
                        onClick={handleRedo} 
                        className={`edit__button ${undoStack.length === 0 ? 'inactive-btn' : ''}`}
                        disabled={undoStack.length === 0}
                      >
                        REDO
                      </button>
                      <button 
                        onClick={handleClear} 
                        className="edit__button"
                      >
                        CLEAR
                      </button>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="save">
                      <button onClick={() => handleSave('jpeg')} className="save__button">JPG</button>
                      <button onClick={() => handleSave('png')} className="save__button">PNG</button>
                      <button onClick={() => handleSave('svg')} className="save__button">SVG</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <div className="signature-intro">
              <h2>Handwritten Signature Generator</h2>
              <p>
                Signing your name on things isn't as prevalent as it used to be. Few people use paper checks anymore and most shops and restaurants don't need you to sign a piece of paper with your bill. However, digital signatures are still important for drafting documents and letters, especially legal materials. They also are terrific for graphics on personal and business websites. But how do you generate a digital version of your signature for these situations?
              </p>
              
              <p>
                That's where our signature generator tools come in! We have a pair of online tools that can help you create a digital image of your actual hand-drawn signature or create a fully digital signature from your typed name.
              </p>
              
              <p>
                Regardless of which tool you use, they are totally free. You can use them as much as you like without cost, there are no watermarks on your images, and you don't need to register or enter your email. We even offer multiple image formats, colors, line weights, and typefaces.
              </p>
              
              <h3>Drawn Handwritten Signature Generator</h3>
              <p>
                This tool is perfect if you already have a cool signature design. You can draw out your signature in your personal style using your mouse, finger, or stylus, and our server will convert it into an image. You can then save this image in various formats for adding to documents, websites, etc.
              </p>
              
              <h3>Typed Handwritten Signature Generator</h3>
              <p>
                If you don't already have a great signature design for when you're putting pen to paper, this tool is what you need. Our server will use your typed name and create a professional-looking script version. We do this by utilizing dozens of royalty-free typefaces, which allow you to find a signature that matches your unique brand or style. Once designed, you can save it as an image and paste it wherever needed.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HandwrittenSignature; 