import React from 'react';
import { Container, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

const FAQ = () => {
  const [openSection, setOpenSection] = useState('');
  
  const toggle = (section) => {
    setOpenSection(openSection === section ? '' : section);
  };

  const faqs = [
    {
      id: 'email-1',
      category: 'Email Signature',
      question: 'How do I add the generated signature to my email client?',
      answer: 'We provide detailed guides to help you add signatures to common email clients. You can find step-by-step instructions for Gmail, Outlook, and Apple Mail under the "Help" menu in "Email Signature Guide".',
      updateDate: '2024-12-08'
    },
    {
      id: 'email-2',
      category: 'Email Signature',
      question: 'Can I add social media icons to my signature?',
      answer: 'Yes, you can. In the "Links" tab of the signature generator, you can add links to popular social media platforms including Facebook, Twitter, LinkedIn, Instagram, and YouTube. The system will automatically add corresponding icons for the social media you include.',
     updateDate: '2024-12-08'
    },
    {
      id: 'email-3',
      category: 'Email Signature',
      question: 'How can I change the colors and style of my signature?',
      answer: 'In the "Design" tab, you can customize the background color, text color, border radius, and other style elements. You can also adjust image sizes, spacing, and other details to create a signature that perfectly matches your needs.', updateDate: '2024-12-08'
    },
    {
      id: 'email-4',
      category: 'Email Signature',
      question: 'Is there a size limit for signature images?',
      answer: 'For optimal performance, we recommend uploading logo or profile images no larger than 1MB. Supported image formats include JPG, PNG, and GIF. The system will automatically optimize images to ensure the best display quality.', updateDate: '2024-12-08'

    },
    {
      id: 'email-5',
      category: 'Email Signature',
      question: 'Are the generated signatures responsive?',
      answer: 'Yes, our generated signatures use responsive design and adapt to different devices and email clients. They maintain good display quality whether viewed on desktop, tablet, or mobile devices.', updateDate: '2024-12-08'

    },
    {
      id: 'handwritten-1',
      category: 'Handwritten Signature',
      question: 'How do I use the handwritten signature generator?',
      answer: 'After opening the handwritten signature generator, you can write on the canvas using your mouse, touchpad, or stylus. You can adjust stroke thickness, color, and other settings. When finished, you can save your signature in JPG, PNG, or SVG format.', updateDate: '2024-12-08'

    },
    {
      id: 'handwritten-2',
      category: 'Handwritten Signature',
      question: 'Can I undo my last action?',
      answer: 'Yes. The handwritten signature tool supports Undo and Redo operations. You can also use the Clear button to start over.', updateDate: '2024-12-08'

    },
    {
      id: 'handwritten-3',
      category: 'Handwritten Signature',
      question: 'Can I use the generated handwritten signature for legal documents?',
      answer: 'Our handwritten signature tool is primarily designed for creating signature images. For official legal documents, we recommend using electronic signature services that comply with relevant legal requirements.', updateDate: '2024-12-08'

    },
    {
      id: 'handwritten-4',
      category: 'Handwritten Signature',
      question: 'How do I adjust the thickness of my handwritten signature?',
      answer: 'In the options panel on the right, you can adjust the "Min Width" and "Max Width" sliders to control the range of stroke thickness variation.', updateDate: '2024-12-08'

    },
    {
      id: 'handwritten-5',
      category: 'Handwritten Signature',
      question: 'Do the saved signature images have watermarks?',
      answer: 'No. We provide a completely free service, and the generated signature images do not include any watermarks. You can use them directly in your documents or for other purposes.', updateDate: '2024-12-08'

    }
  ];

  const styles = {
    dateText: {
      fontSize: '0.8rem',
      color: '#666',
      whiteSpace: 'nowrap'
    },
    cardHeader: {
      transition: 'all 0.3s ease'
    }
  };

  return (
    <Container>
      <Helmet>
        <title>FAQ - Email & Handwritten Signature Generator Help | EasySignGen</title>
        <meta name="description" content="Get answers to frequently asked questions about creating email signatures and handwritten signatures. Learn how to customize, save and add signatures to Gmail, Outlook & Apple Mail." />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="FAQ - Email & Handwritten Signature Generator Help | EasySignGen" />
        <meta property="og:description" content="Get answers to frequently asked questions about creating email signatures and handwritten signatures. Learn how to customize, save and add signatures to Gmail, Outlook & Apple Mail." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.easysigngen.com/help/faq" />
        <meta property="og:site_name" content="EasySignGen" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="FAQ - Email & Handwritten Signature Generator Help" />
        <meta name="twitter:description" content="Get answers to frequently asked questions about creating email signatures and handwritten signatures." />

        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.easysigngen.com/help/faq" />

        {/* Schema.org Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": faqs.map(faq => ({
              "@type": "Question",
              "name": faq.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
              }
            })),
            "publisher": {
              "@type": "Organization",
              "name": "EasySignGen",
              "url": "https://www.easysigngen.com"
            }
          })}
        </script>

        {/* Additional Meta Tags for Better SEO */}
        <meta name="author" content="EasySignGen" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="7 days" />
        <meta name="rating" content="safe for kids" />
        <meta name="copyright" content="Copyright © 2024 EasySignGen" />
      </Helmet>

      <h1 className="text-center mb-4" style={{color:"#8214B6"}}>
        Frequently Asked Questions
      </h1>
      
      <div className="faq-container">
        {faqs.map((faq) => (
          <Card key={faq.id} className="mb-2">
            <CardHeader 
              className="cursor-pointer" 
              onClick={() => toggle(faq.id)}
              style={{
                backgroundColor: openSection === faq.id ? '#f8f0fc' : 'white',
                cursor: 'pointer',
                borderLeft: openSection === faq.id ? '4px solid #8214B6' : 'none'
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">
                  <span className="badge badge-pill mr-2" 
                    style={{
                      backgroundColor: '#8214B6',
                      color: 'white'
                    }}
                  >
                    {faq.category}
                  </span>
                  {faq.question}
                </h5>
                <small style={styles.dateText}>
                  Updated: {new Date(faq.updateDate).toLocaleDateString()}
                </small>
              </div>
            </CardHeader>
            <Collapse isOpen={openSection === faq.id}>
              <CardBody>
                {faq.answer}
              </CardBody>
            </Collapse>
          </Card>
        ))}
      </div>
    </Container>
  );
};

export default FAQ; 