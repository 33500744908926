import React, { Component, useState } from 'react'
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarToggler, Collapse } from 'reactstrap';
import {Link} from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const AppNavBar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [helpDropdownOpen, setHelpDropdownOpen] = useState(false);

  return (
    <Navbar color="light" className="mb-3 px-4" light expand="md">
      <div className="d-flex align-items-center">
        <NavbarBrand href="/" className="mr-4">
          <img alt="logo-img" src={require('../img/logo.png')} width="256" height="60" />
        </NavbarBrand>
        
        <Nav navbar className="d-flex flex-row">
        <Dropdown nav isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <DropdownToggle nav caret>
              Products
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href="/">Email Signature Generator</DropdownItem>
              <DropdownItem href="/handwritten-signature">
                Handwritten Signature Generator
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
         

          <NavItem>
            <Link to="/blog" className="text-decoration-none">
              <div className='mx-2 px-3 py-2'>
                <strong>Blog</strong>
              </div>
            </Link>
          </NavItem>

          <NavItem>
            <Link to="/contact" className="text-decoration-none">
              <div className='mx-2 px-3 py-2'>
                <strong>Contact</strong>
              </div>
            </Link>
          </NavItem>

          <Dropdown nav isOpen={helpDropdownOpen} toggle={() => setHelpDropdownOpen(!helpDropdownOpen)}>
            <DropdownToggle nav caret>
              Help
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag={Link} to="/help/email-signature-guide">
                Email Signature Guide
              </DropdownItem>
              <DropdownItem tag={Link} to="/help/faq">
                FAQ
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </div>
    </Navbar>
  );
};

export default AppNavBar;