import { v2 as cloudinary } from 'cloudinary';

// 配置 Cloudinary
cloudinary.config({ 
    cloud_name: 'dceuvnlv1', 
    api_key: '862675495742879', 
    api_secret: 'N3xR1yT6dGuoW9JKF8J_D9f8jbk'
});

// 上传图片
const uploadImage = async (file) => {
  try {
    const uploadResult = await cloudinary.uploader.upload(file, {
      // 可以指定文件夹
      folder: 'easysigngen',
      // 可以指定转换参数
      transformation: {
        width: 1000,
        crop: "limit"
      }
    });

    return {
      url: uploadResult.secure_url,
      publicId: uploadResult.public_id
    };
  } catch (error) {
    console.error('上传失败:', error);
    throw error;
  }
};

// 删除图片
const deleteImage = async (publicId) => {
  try {
    const result = await cloudinary.uploader.destroy(publicId);
    return result;
  } catch (error) {
    console.error('删除失败:', error);
    throw error;
  }
};

// 获取优化后的图片URL
const getOptimizedUrl = (publicId, options = {}) => {
  const {
    width,
    height,
    crop = 'fill',
    quality = 'auto'
  } = options;

  return cloudinary.url(publicId, {
    width,
    height,
    crop,
    fetch_format: 'auto',
    quality
  });
};

// 获取自动裁剪的图片URL
const getAutoCropUrl = (publicId, width, height) => {
  return cloudinary.url(publicId, {
    crop: 'auto',
    gravity: 'auto',
    width,
    height,
    fetch_format: 'auto',
    quality: 'auto'
  });
};

export {
  uploadImage,
  deleteImage,
  getOptimizedUrl,
  getAutoCropUrl
};
