// 导入 Firebase 核心模块
import firebase from 'firebase/app'
// 导入 Firebase 存储模块，用于处理文件上传和下载
import 'firebase/storage'  
// 导入 Firebase 分析模块，用于跟踪应用使用情况
import 'firebase/analytics'

// Firebase 项目配置信息
var firebaseConfig = {
    apiKey: "AIzaSyC4OJmSzhYhltPCjeG9VCmmLZ39rz62cko",        // API密钥
    authDomain: "signautre-c6699.firebaseapp.com",             // 授权域名
    databaseURL: "https://signautre-c6699.firebaseio.com",     // 数据库URL
    projectId: "signautre-c6699",                              // 项目ID
    storageBucket: "signautre-c6699.appspot.com",             // 存储桶地址
    messagingSenderId: "505457762304",                         // 消息发送者ID
    appId: "1:505457762304:web:43d483ebcce8f402c878e5",      // 应用ID
    measurementId: "G-0T1Z95C9P4"                             // 测量ID，用于 Analytics
};

// 初始化 Firebase 应用
firebase.initializeApp(firebaseConfig);
// 初始化 Firebase Analytics 服务
firebase.analytics()
// 获取 Firebase Storage 实例
const storage = firebase.storage()

// 导出 storage 实例和 firebase 对象
// storage 用于文件操作，firebase 作为默认导出
export { storage, firebase as default };