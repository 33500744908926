import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import blogData from '../../data/blogData.json';

const BlogPost = () => {
  const { slug } = useParams();
  const post = blogData.posts.find(post => post.slug === slug);

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <>
      <Helmet>
        <title>{post.title} | EasySignGen Blog</title>
        <meta name="description" content={post.description} />
        <link rel="canonical" href={`https://www.easysigngen.com/blog/${slug}`} />
        
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.easysigngen.com/blog/${slug}`} />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.description} />
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": post.title,
            "datePublished": post.date,
            "dateModified": post.date,
            "description": post.description,
            "author": {
              "@type": "Organization",
              "name": "EasySignGen"
            },
            "publisher": {
              "@type": "Organization",
              "name": "EasySignGen",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.easysigngen.com/logo.png"
              }
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `https://www.easysigngen.com/blog/${slug}`
            }
          })}
        </script>
      </Helmet>

      <Container className="py-4">
        <Link to="/blog" className="btn btn-outline-secondary mb-4 rounded-pill">
          Back to Blog List
        </Link>
        <h1 style={{color: "#8214B6"}}>{post.title}</h1>
        <div className="text-muted mb-4">{post.date}</div>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </Container>
    </>
  );
};

export default BlogPost;