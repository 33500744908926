import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container } from 'reactstrap';
import BlogList from './BlogList';
import BlogPost from './BlogPost';

const Blog = () => {
  let { path } = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route exact path={path}>
          <BlogList />
        </Route>
        <Route path={`${path}/:slug`}>
          <BlogPost />
        </Route>
      </Switch>
    </Container>
  );
};

export default Blog; 